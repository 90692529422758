import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/layout'

import { Footer } from '../components/footer'
import { Teaser } from '../components/teaser'

import { Grid, Row, Col } from '../components/common/grid'
import { Space } from '../components/common/space'
import { H4 } from '../components/common/heading'

const StyledH4 = styled(H4)`
  margin-bottom: 10px;
`

const title = 'Albedo Sports'
const description = 'Impressum & Datenschutz'
const keywords = ''

const IndexPage = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} />

    <Grid>
      <Row centered>
        <Col xs={12} md={5}>
          <div>
            <Space bottom={50}>
              <StyledH4>Informationspflicht</StyledH4>
              <p>
                §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
                Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz
              </p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Firma</StyledH4>
              <p>Albedo OG</p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Firmensitz</StyledH4>
              <p>Lindenstraße 4/2, Bad Hall, Austria</p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Firmenbuchnummer</StyledH4>
              <p>474457g</p>
            </Space>
            <Space bottom={50}>
              <StyledH4>UID Nummer</StyledH4>
              <p>ATU72224103</p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Gewerbebehörde</StyledH4>
              <p>Bezirkshauptmannschaft Steyr-Land</p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Gerichtsstand</StyledH4>
              <p>Landesgericht Steyr</p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Gewerbebezeichnung</StyledH4>
              <p>Freies Gewerbe</p>
            </Space>
          </div>
        </Col>
        <Col xs={0} md={2} />
        <Col xs={12} md={5}>
          <div>
            <Space bottom={50}>
              <StyledH4>Rechtlicher Hinweis</StyledH4>
              <p>
                Durch die technischen Eigenschaften des Internet kann keine
                Gewähr für die Authentizität, Richtigkeit und Vollständigkeit
                der im Internet zur Verfügung gestellten Informationen
                übernommen werden. Es wird auch keine Gewähr für die
                Verfügbarkeit Webseite und ihrer Inhalte übernommen. Jede
                Haftung für unmittelbare, mittelbare oder sonstige Schäden,
                unabhängig von deren Ursachen, die aus der Benutzung oder
                Nichtverfügbarkeit der Daten und Informationen dieser Homepage
                entstehen, wird, soweit rechtlich zulässig, ausgeschlossen.
              </p>
            </Space>

            <Space bottom={50}>
              <StyledH4>Nutzung der Website</StyledH4>
              <p>
                Alle Rechte, auch die der Übersetzung, des Nachdrucks und die
                der Vervielfältigung, vorbehalten. Kein Teil darf ohne
                schriftliche Genehmigung des Inhabers in irgendeiner Form sprich
                Fotokopie, Microfilm oder ein anderes Verfahren, reproduziert
                oder unter Verwendung elektronischer Systeme verarbeitet,
                vervielfältigt oder verbreitet werden. Sie erhalten durch das
                Downloaden des Websiteinhalts keinerlei Eigentümerrechte!
              </p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Persönliche Daten</StyledH4>
              <p>
                Soweit auf unseren Seiten personenbezogene Daten (Name,
                Anschrift, e-Mail Adressen, etc.) erhoben werden, erfolgt dies
                stets auf freiwilliger Basis. Der Nutzung von im Rahmen der
                Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                Übersendung von nicht ausdrücklich angeforderter Werbung und
                Informationsmaterialien wird hiermit ausdrücklich widersprochen.
                Wir behalten uns ausdrücklich rechtliche Schritte im Falle der
                unverlangten Zusendung von Werbeinformationen, etwa durch
                Spam-Mails, vor!
              </p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Auskunftsrecht</StyledH4>
              <p>
                Sie haben jederzeit das Recht auf Auskunft über die bezüglich
                Ihrer Person gespeicherten Daten, deren Herkunft und Empfänger
                sowie den Zweck der Speicherung
              </p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Aktualisierungen und Änderungen</StyledH4>
              <p>
                Diese Website wird in regelmäßigen Abständen gewartet und
                aktualisiert. Wir übernehmen keinerlei Haftung für die
                Richtigkeit und Aktualität der gemachten Angaben.
              </p>
            </Space>
            <Space bottom={50}>
              <StyledH4>Links</StyledH4>
              <p>
                Die Albedo OG distanziert sich von den externen Links welche auf
                der Website veröffentlicht werden. Für die Inhalte der
                verlinkten Seiten ist stets der jeweilige Betreiber der Seiten
                verantwortlich.
              </p>
            </Space>
          </div>
        </Col>
      </Row>
    </Grid>

    <Footer />
  </Layout>
)

export default IndexPage
